<template>
  <div>
    <b-card>
      <b-row class="justify-content-end">
        <b-col
          md="auto"
        >
          <b-button
            variant="primary"
            @click="createModal()"
          >
            Nouvelle Question
          </b-button>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="6">
          <b-form-group
            label="Ref ou Libellé question"
            label-for="question"
          >
            <b-form-input
              id="question"
              v-model="filter.question"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <label>Statut</label>
            <v-select
              v-model="filter.actif"
              label="statut"
              placeholder="Actif / Inactif"
              :options="['Actif', 'Inactif']"
            >
              <div slot="no-options">
                Option inexistante
              </div>
            </v-select>
          </b-form-group>
        </b-col>


        <b-col md="6">
          <b-form-group label="Fonction">
            <v-select
              v-model="filter.fonctions"
              :options="getFonctions"
            />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group label="Compétence">
            <v-select
              v-model="filter.competences"
              :options="getCompetences"
            />
          </b-form-group>
        </b-col>

      </b-row>

      <b-row class="justify-content-end">
        <b-col
          md="auto"
        >
          <b-button
            variant="primary"
            @click="search()"
          >
            Rechercher
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <b-overlay :show="overlay">
        <vue-good-table
          :columns="columns"
          :rows="questions"
          :sort-options="{
            enabled: false,
          }"
          class="table b-table b-table-fixed"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span
              v-if="props.column.field == 'update'"
            >
              <b-button
                id="detailIcon"
                variant="primary"
                @click="createModal(props.row.id)"
              >
                <feather-icon
                  icon="EditIcon"
                />
              </b-button>
              <!-- <ModalUpdate /> -->
            </span>
            <span v-else-if="props.column.field == 'ref'">{{ refQuestion(props.row.id) }}</span>
            <div v-else-if="props.column.field == 'actif'">
              <center>
                <b-badge
                  pill
                  :variant="getVariant(props.row.actif)"
                >
                  {{ getBadgeLabel(props.row.actif) }}
                </b-badge>
                <b-form-checkbox
                  :key="props.row.id"
                  v-model="props.row.actif"
                  inline
                  switch
                  class="mt-1 mr-0 no-label"
                  size="sm"
                  @change="setActif(props.row.id, props.row.actif)"
                />
              </center>

            </div>
          </template>
        </vue-good-table>

        <b-row class="mt-2">
          <b-col
            cols="6"
            class="text-left"
          >
            <b-form-group
              class="mb-0"
            >
              <label class="d-inline-block text-sm-left mr-50">Afficher</label>
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                :options="pageOptions"
                class="w-25"
              />
              <span class="ml-2">sur {{ totalRows }} lignes</span>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="right"
              size="sm"
              class="my-0"
            />
          </b-col>

        </b-row>
      </b-overlay>

    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BBadge,
  BButton,
  BCol,
  BRow,
  BFormGroup, BFormInput, BFormCheckbox,
  BOverlay,
  BPagination, BFormSelect,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { VueGoodTable } from 'vue-good-table'

export default {
  components: {
    BCard,
    BBadge,
    BButton,
    VueGoodTable,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    vSelect,
    BOverlay,
    BPagination,
    BFormSelect,
  },
  data() {
    return {
      overlay: false,
      filter: {
        question: '',
        actif: null,
        fonctions: '',
        competences: '',
      },
      commonTablesproperties: {
        tdClass: 'text-center',
        thClass: 'text-center',
      },
      questions: [],
      columns: [
        { field: 'update', tdClass: 'text-center' },
        { label: 'Ref', field: 'ref' },
        { label: 'Question', field: 'libelle' },
        { label: 'Fonctions', field: 'fonctions' },
        { label: 'Competences', field: 'competences' },
        { label: 'Statut', field: 'actif', type: 'boolean' },
      ],
      rows: [],
      perPage: 10,
      currentPage: 1,
      pageOptions: [10, 25, 50],
      totalRows: 0,
      competences: [],
      fonctions: [],
    }
  },
  computed: {
    getFonctions() {
      return this.fonctions?.map(elem => ({
        id: elem.id,
        label: elem.libelle_fonction,
      }))
    },
    getCompetences() {
      return this.competences?.map(elem => ({
        id: elem.id,
        label: elem.libelle_competence,
      }))
    },
  },
  watch: {
    perPage() {
      this.currentPage = 1
      this.search()
    },
    currentPage() {
      this.search()
    },
  },
  beforeMount() {
    this.$store.dispatch('fonctionCompetence/getAllCompetences', { actif: 'Actif' }).then(v => this.competences = v )
    this.$store.dispatch('fonctionCompetence/getAllFonctions', { actif: 'Actif' }).then(v => this.fonctions = v )
  },
  mounted() {
    this.search()
  },
  methods: {
    createModal(id) {
      if (id) {
        this.$router.push({ name: 'admin-new-questions-reponses', params: { id } })
      } else {
        this.$router.push({ name: 'admin-new-questions-reponses' })
      }
    },
    search() {
      const filtrage = {}
      if (this.filter.question !== '') filtrage.question = this.filter.question
      if (this.filter.actif !== null) filtrage.statut = this.filter.actif
      if (this.filter.fonctions !== '' && this.filter.fonctions !== null) filtrage.fonctions = this.filter.fonctions.label
      if (this.filter.competences !== '' && this.filter.competences !== null) filtrage.competences = this.filter.competences.label
      filtrage.limit = this.perPage
      filtrage.offset = (this.currentPage - 1) * this.perPage

      this.overlay = true
      this.$store.dispatch('questionReponse/getAllQuestions', filtrage).then(
        res => {
          this.questions = res.question
          this.totalRows = res.total
          this.overlay = false
        },
      )
    },
    refQuestion(id) {
      let ref = ''
      if (id < 10) ref = `00000${id}`
      else if (id < 100) ref = `0000${id}`
      else if (id < 1000) ref = `000${id}`
      else if (id < 10000) ref = `00${id}`
      else if (id < 100000) ref = `0${id}`
      else ref = id

      return `Q${ref}`
    },
    getBadgeLabel(actif) {
      return actif ? 'Actif' : 'Inactif'
    },
    getVariant(actif) {
      let variant = ''
      if (actif) {
        variant = 'light-success'
      } else {
        variant = 'light-danger'
      }
      return variant
    },
    setActif(id, actif) {
      this.$store.dispatch('questionReponse/updateQuestion', { id, actif })
    },
  },
}
</script>
<style>
.no-label .custom-control-label { padding-left: 0; }

</style>
